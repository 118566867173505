.app {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  min-height: 20vh;
  color: white;
}

.map-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85vh;
  width: 100vw;
  padding-top: 15vh;
}

.map-toolbar {
  background: white;
  height: 5vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;

  .logo {
    width: 105px;
    height: 15px;
    align-self: center;
    padding-left: 15px;
  }
}

.map-actions-div {
  display: flex;

  .map-actions {
    display: flex;
  }

  .map-action-img {
    width: 45px;
    height: 25px;
    align-self: center;
    padding-left: 17px;
    cursor: pointer;
  }

  .alerts {
    width: 42px;
    height: 30px;
    padding-left: 12px !important;
    align-self: center;
  }

  .menu {
    width: 30px;
    height: 25px;
    margin-left: 5em;
    margin-right: 2em;
    align-self: center;
  }
}

.map-container {
  position: relative;
  height: 95vh;
  width: 100%;
}

.events-container {
  position: absolute;
  z-index: 100;
  bottom: 0em;
  right: 2em;
  top: 4em;
}

.events {
  padding: 3px 15px;
  background-color: white;
  color: black;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  width: calc(370px - 4rem);
  z-index: 100;
  margin-bottom: 2px;
  height: 88%;
  max-height: calc(88% - 4px);
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;

    .text {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .circle {
      width: 25px;
      height: 25px;
      background-color: black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 12px;
      font-weight: bold;
      margin-left: 10px;
    }

    .events-filter {
      width: 25px;
      height: 25px;
    }
  }

  .header-hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 0.5px solid #282c34;
    margin: 1em 0;
    padding: 0;
  }
}

.event {
  padding-right: 5px;
  
  .event-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 4px;
  }

  .event-location {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }

  .event-pin {
    width: 23px;
    height: 18px;
    padding-right: 5px;
  }

  .event-date {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
  }

  .event-clock {
    width: 20px;
    height: 15px;
    padding-right: 5px;
  }

  .event-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    color: grey;
    font-size: 11px;
    padding-left: 23px;
  }

  .event-desc {
    text-align: left;
    font-size: 13px;
    padding-left: 23px;
  }

  .event-imp {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 23px;
    color: red;
    padding-bottom: 15px;
  }

  .event-circle-red {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
  }

  .event-hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 0.5px solid #d9d7d7;
    margin: 1em 0;
    padding: 0;
  }
}

body,
a,
button,
input {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1.15;
}

body {
  position: relative;
  background: url('https://image.ibb.co/jGk9RR/bg.jpg') no-repeat center fixed;
  background-size: 100% 100%;
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  body {
    min-height: 100%;
    height: auto;
    max-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: visible;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
button:active,
button:hover {
  outline: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a:active,
a:hover {
  outline: 0;
}

.panel_blur,
.panel {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .panel_blur,
  .panel {
    width: 350px;
    height: 450px;
    overflow-y: visible;
  }
}

/**
 * Blur
 */
.panel_blur {
  position: absolute;
  background: url('https://image.ibb.co/jGk9RR/bg.jpg') no-repeat center fixed;
  background-size: 100% 100%;
  filter: blur(5px);
}

/**
 * Panel 
 */
.panel {
  position: relative;
  z-index: 1;
}
.panel__register-form,
.panel__password-form {
  display: none;
}
.panel__register-form .form__submit {
  margin-top: 5px !important;
}
.panel__form-wrapper {
  width: 100%;
  height: 100%;
  padding: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
@media screen and (min-width: 768px) {
  .panel__form-wrapper {
    padding: 15% 15%;
    border-radius: 10px;
    overflow-y: visible;
  }
}
.panel__prev-btn {
  width: 24px;
  height: 24px;
  background: none;
  padding: 0;
}
.panel__prev-btn svg {
  transition: fill 0.3s;
}
.panel__prev-btn:hover > svg {
  fill: #ff1552;
}
.panel__headers {
  padding: 10px 0;
  text-align: center;
}
.panel__header {
  font-size: 1.375rem;
}
.panel__header:first-child {
  padding-bottom: 5px;
}
.panel__header.active > .panel__link {
  color: #ff1552;
  font-size: 3rem;
}
.panel__link {
  color: inherit;
  transition: all 0.3s;
  font-weight: 600;
}

/**
 * Styles for <form>
 */
.form__row {
  position: relative;
  padding-top: 40px;
}
.form__row.has-error > .form__error:after {
  display: block;
}
.form__input {
  width: 100%;
  padding: 5px 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background: none;
}
.form__input:focus,
.form__input:active {
  outline: 0;
}
.form__input:focus ~ .form__label,
.form__input:active ~ .form__label,
.form__input:valid ~ .form__label {
  bottom: 30px;
  font-size: 0.75rem;
}
.form__input:focus ~ .form__bar,
.form__input:active ~ .form__bar {
  left: 0;
  right: 0;
  width: 100%;
}
.form__bar {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  display: block;
  width: 0;
  height: 2px;
  background-color: #ff1552;
  transition: all 0.3s;
}
.form__label {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  pointer-events: none;
}
.form__submit {
  width: 100%;
  padding: 10px 0;
  margin-top: 30px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  background-color: #ff1552;
  cursor: pointer;
  text-align: center;
}
.form__retrieve-pass {
  display: block;
  padding: 15px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
}
.form__retrieve-pass:hover {
  color: #fff;
}
.form__error {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  height: 20px;
  color: #ff1552;
  font-size: 0.75rem;
  line-height: 20px;
}
.form__error:after {
  content: 'x';
  position: absolute;
  bottom: 20px;
  right: -20px;
  display: none;
  width: 20px;
  height: 25px;
  font-size: 1.125rem;
  line-height: 25px;
  text-align: center;
  color: #ff1552;
}
.form__info {
  text-align: center;
}
